:root {
  --bgGray: #f2f2f2;
  --brdrStrk: #ccc;
  --fontRoboto: "Roboto Mono", monospace;
  --fontPoppins: "Poppins", sans-serif;
  --colorMain: #333;
  --formBg: #ffffff;
  --formText: #b8b8b8;
}

html {
  background-color: var(--bgGray);
}

body {
  background-color: var(--bgGray);
  color: var(--colorMain);
  font-family: var(--fontRoboto);
  font-size: 12px;
  line-height: 1.2;
  padding: 0px 12px 0px 12px;
  overflow-x: hidden;
  margin: 0px 20% 0px 20%;
}

@media (max-width: 600px) {
  body {
    margin: 0 0 0 0;
  }
}

hr {
  border: none;
  border-top: 1px solid var(--brdrStrk);
  margin: 10px 0;
  clear: both;
}

header {
  position: sticky;
  top: 0px;
  background-color: var(--bgGray);
  z-index: 99;
  padding-top: 12px;
}

.top-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background-color: var(--formBg);
  border: 1px solid var(--brdrStrk);
  border-radius: 5px;
  color: var(--colorMain);
  cursor: pointer;
  margin-bottom: 6px;
}

h1 {
  font-family: var(--fontPoppins);
  font-size: 24px;
}

ul,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  padding-bottom: 4px;
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--brdrStrk);
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.left-content {
  display: flex;
  align-items: center;
}

.strikethrough {
  text-decoration: line-through;
}

input[type="checkbox"] {
  appearance: none;
  background-color: #fafafa;
  border: 1px solid var(--brdrStrk);
  padding: 8px;
  border-radius: 3px;
  position: relative;
  margin-right: 12px;
}

input[type="checkbox"]:checked::after {
  font-family: var(--fontRoboto);
  font-size: 16px;
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  color: #99a1a7;
}

input[type="checkbox"]:hover {
  border: 1px solid var(--brdrStrk);
}

.form-top-row {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  gap: 6px;
}

.emoji-product-form-wrapper {
  display: flex;
}

.emoji-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4e4e4;
  border-radius: 5px 0px 0cqh 5px;
  border-width: 1px 0px 1px 1px;
  border-color: #c3c6c8;
  border-style: solid;
  padding: 2px 4px;
  cursor: pointer;
  min-width: 20px;
  min-height: 22px;
}

.emoji-button-icon {
  width: 10px;
}

.emoji-button-selection {
  font-size: 10px;
}

.product-form-input {
  background-color: var(--formBg);
  font-family: var(--fontRoboto);
  font-size: 12px;
  width: 100%;
  border-radius: 0px 5px 5px 0px;
  border-width: 1px;
  border-color: #c3c6c8;
  border-style: solid;
  padding-left: 6px;
}

.product-form-input::placeholder {
  color: #c3c6c8;
}

.default-form-input {
  background-color: var(--formBg);
  font-family: var(--fontRoboto);
  font-size: 12px;
  width: 100%;
  border-radius: 5px;
  border-width: 1px;
  border-color: #c3c6c8;
  border-style: solid;
  padding: 2px 6px;
  margin-left: 0px;
  color: #c3c6c8;
}

.default-form-input::placeholder {
  color: #c3c6c8;
}

.submit-button {
  font-family: var(--fontRoboto);
  font-size: 12px;
  align-items: center;
  justify-content: center;
  color: var(--formBg);
  background-color: rgb(32, 32, 32);
  border: 1px solid var(--brdrStrk);
  border-radius: 20px;
  cursor: pointer;
}

em-emoji-picker {
  box-shadow: none;
  width: 100%;
  font-size: 12px;
  padding-bottom: 12px;
  background-color: white;
}

/* .entry-form input,
.entry-form select {
  max-width: 96px;
  background-color: var(--formBg);
  border: 1px solid var(--brdrStrk);
  border-radius: 5px;
  padding: 2px 4px;
  color: var(--colorMain);
  margin-bottom: 4px;
  margin-right: 4px;
}

.entry-form input::placeholder {
  color: var(--brdrStrk);
} */

.submit-button {
  font-family: var(--fontRoboto);
  font-size: 12px;
  align-items: center;
  justify-content: center;
  color: var(--formBg);
  background-color: rgb(32, 32, 32);
  border: 1px solid var(--brdrStrk);
  border-radius: 6px;
  cursor: pointer;
  width: 100%;
  padding: 4px 0px 4px 0px;
}

.delete-button {
  cursor: pointer;
  color: rgb(168, 168, 168);
  margin-left: auto;
  margin-right: 12px;
  transform: rotate(45deg);
}

.delete-cross {
  font-size: 16px;
  color: rgb(168, 168, 168);
  transform: rotate(45deg);
  margin-right: 6px;
}

.deleteCheckedButton {
  font-family: var(--fontRoboto);
  font-size: 12px;
  display: flex;
  position: fixed;
  width: 60%;
  align-items: center;
  justify-content: center;
  color: var(--formBg);
  background-color: rgb(32, 32, 32);
  border: 1px solid var(--brdrStrk);
  border-radius: 50px;
  cursor: pointer;
  padding: 12px 0px;
  margin-bottom: 24px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.item-list {
  padding-bottom: 72px;
}

.category-button {
  font-family: var(--fontRoboto);
  font-size: 12px;
  color: var(--formBg);
  background-color: rgb(32, 32, 32);
  border: 1px solid var(--brdrStrk);
  border-radius: 20px;
  cursor: pointer;
  padding: 6px 12px;
  margin: 1px;
}

.category-button:hover {
  background-color: rgb(68, 68, 68);
}

.message {
  padding-left: 36px;
  color: #ababab;
}
